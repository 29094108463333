import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from 'environments/environment';

@Injectable()
export class DownloadService
{   
    private API_ENDPOINT:string;

    constructor( private _httpClient: HttpClient)
    {
        this.API_ENDPOINT = environment.API_DOWNLOAD;
    }

    getUrlSign(tk:string): Observable<any>  {
        const headers = new HttpHeaders({
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${tk}`
          })
       return this._httpClient.get<any>(this.API_ENDPOINT + '/url/sign',{headers});
    }

    getUrlWauthSign(): Observable<any>  {
        const headers = new HttpHeaders({
            'Content-Type': 'application/json'
          })
       return this._httpClient.get<any>(this.API_ENDPOINT + '/url/wauth/sign',{headers});
    }

}