import { Component, OnInit } from '@angular/core';

@Component({
    selector: 'app-hierbas-del-oasis',
    templateUrl: './hierbasdeloasis.component.html',
    styleUrls: ['./hierbasdeloasis.component.scss']
})

export class HierbasDelOasisComponent implements OnInit {

    pdfSrc:string = "../../assets/files/HDO.pdf";
    page: number = 1;

    constructor() { }

    ngOnInit() {
    }

    public changePage(number){
        this.page += number;
    }


}
