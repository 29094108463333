import { Component, OnInit } from '@angular/core';
import { Productos } from './productos.model';
import { Router } from '@angular/router';

@Component({
    selector: 'app-productos',
    templateUrl: './productos.component.html',
    styleUrls: ['./productos.component.scss']
})

export class ProductosComponent implements OnInit {

    productos:Array<Productos> = [
        {
            nombre: 'Semillas y Harinas',
            folderName:' ../../assets/img/semillas-harinas/',
            subProductos:[
                {
                    nombre: 'Lino',
                    imageName: 'semilla1.jpg'
                },
                {
                    nombre: 'Quinoa',
                    imageName: 'semilla2.jpg'
                },
                {
                    nombre: 'Chia',
                    imageName: 'semilla3.jpg'
                },
                {
                    nombre: 'Sesamo Integral Premium',
                    imageName: 'semilla4.jpg'
                },
                {
                    nombre: 'Sesamo Blanco',
                    imageName: 'semilla5.jpg'
                },
                {
                    nombre: 'Girasol Pelado',
                    imageName: 'semilla6.jpg'
                },
                {
                    nombre: 'MIX DE SEMILLAS MUNDO PAMPA',
                    imageName: 'MIX-DE-SEMILLAS-FOTO-1.jpg'
                },
                {
                    nombre: 'HARINA DE ALMENDRAS',
                    imageName: 'HARINA-DE-ALMENDRAS-FOTO-2.jpg'
                },
                {
                    nombre: 'HARINA DE CASTAÑAS DE CAJU',
                    imageName: 'HARINAS-DE-CASTANAS-DE-CAJU-FOTO-3.jpg'
                },
                {
                    nombre: 'HARINA DE COCO ORGANICAS',
                    imageName: 'HARINA-DE-COCO-ORGANICA-FOTO-4.jpg'
                }
            ]
        },
        {
            nombre: 'Frutos Secos',
            folderName:' ../../assets/img/frutos-secos/',
            subProductos:[
                {
                    nombre: 'Nuez con Cascara',
                    imageName: 'fs1.jpg'
                },
                {
                    nombre: 'Nuez Mariposa Extra Light',
                    imageName: 'fs2.jpg'
                },
                {
                    nombre: 'Nuez Cuarto Extra Light',
                    imageName: 'fs3.jpg'
                },
                {
                    nombre: 'Nuez Mariposa Dorada',
                    imageName: 'fs4.jpg'
                },
                {
                    nombre: 'Almendra Pelada chilena',
                    imageName: 'fs5.jpg'
                },
                {
                    nombre: 'Almendra Pelada Carmel',
                    imageName: 'fs6.jpg'
                },
                {
                    nombre: 'Castaña de Caju W3',
                    imageName: 'fs7.jpg'
                },
                {
                    nombre: 'Castaña de Caju W4',
                    imageName: 'fs8.jpg'
                },
                {
                    nombre: 'Castaña de Caju Tostada con Sal',
                    imageName: 'fs9.jpg'
                },
                {
                    nombre: 'Castaña de Caju Tostada sin Sal',
                    imageName: 'fs10.jpg'
                },
                {
                    nombre: 'Avellana con Cascara',
                    imageName: 'fs11.jpg'
                },
                {
                    nombre: 'Avellana Pelada Grande',
                    imageName: 'fs12.jpg'
                },
                {
                    nombre: 'Pistacho Pelado',
                    imageName: 'fs13.jpg'
                },
                {
                    nombre: 'Pistacho Tostado Salado con Cascara',
                    imageName: 'fs14.jpg'
                },
                {
                    nombre: 'ALMENDRAS GUARA',
                    imageName: 'ALMENDRAS-GUARA-FOTO-5.jpg'
                },
                {
                    nombre: 'MARIPOSA COMUN',
                    imageName: 'MARIPOSA-COMUN-FOTO-6.jpg'
                },
                {
                    nombre: 'CUARTOS COMUNES',
                    imageName: 'CUARTOS-COMUNES-FOTO-7.jpg'
                },
                {
                    nombre: 'MANI VAINA',
                    imageName: 'MANI-VAINA-FOTO-8.jpg'
                },
                {
                    nombre: 'MANI REPELADO SIN SAL',
                    imageName: 'MANI-REPELADO-SIN-SAL-FOTO-9.jpg'
                },
                {
                    nombre: 'MANI REPELADO CON SAL',
                    imageName: 'MANI-REPELADO-CON-SAL-FOTO-10.jpg'
                },
                {
                    nombre: 'MANI GARRAPIÑADA',
                    imageName: 'MANI-GARRAPINADA-FOTO-11.jpg'
                },
                {
                    nombre: 'PASTA DE MANI KING',
                    imageName: 'PASTA-DE-MANI-KING-FOTO-12.jpg'
                }
            ]
        },
        {
            nombre: 'Mix de Frutas',
            folderName:' ../../assets/img/mix-frutas/',
            subProductos:[
                {
                    nombre: 'MIX ESPECIAL DELUXE',
                    imageName: 'FOTO-106.jpg'
                },
                {
                    nombre: 'MIX COMUN PREMIUM CON CAJU',
                    imageName: 'FOTO-107.jpg'
                },
                {
                    nombre: 'MIX FIESTA',
                    imageName: 'FOTO-108.jpg'
                },
                {
                    nombre: 'MIX SECO ESPECIAL',
                    imageName: 'FOTO-109.jpg'
                },
                {
                    nombre: 'MIX SECO DELUXE',
                    imageName: 'FOTO-110.jpg'
                },
                {
                    nombre: 'MIX CRIOLLO',
                    imageName: 'FOTO-111.jpg'
                },
                {
                    nombre: 'MIX CRIOLLO CON BANANA',
                    imageName: 'FOTO-112.jpg'
                },
                {
                    nombre: 'MIX CRIOLLO ESPECIAL CON BANANAS',
                    imageName: 'FOTO-113.jpg'
                },
                {
                    nombre: 'MIX TROPICAL DELUXE',
                    imageName: 'FOTO-114.jpg'
                },
                {
                    nombre: 'MIX SUPER ENERGIA ESPECIAL',
                    imageName: 'FOTO-115.jpg'
                },
                {
                    nombre: 'MIX PATAGONICO',
                    imageName: 'FOTO-116.jpg'
                },
                {
                    nombre: 'MIX DESAYUNO',
                    imageName: 'FOTO-117.jpeg'
                },
                {
                    nombre: 'MIX DEPORTIVO',
                    imageName: 'FOTO-118.jpeg'
                },
                {
                    nombre: 'MIX DE SEMILLAS',
                    imageName: 'FOTO-119.jpg'
                },
                {
                    nombre: 'MIX COMUN ECONOMICO',
                    imageName: 'FOTO-138.jpg'
                },
                {
                    nombre: 'MIX COMUN PREMIUM',
                    imageName: 'FOTO-139.jpg'
                },
                {
                    nombre: 'MIX SECO COMUN',
                    imageName: 'FOTO-140.jpg'
                },
                {
                    nombre: 'MIX SUPER ENERGIA',
                    imageName: 'FOTO-141.jpg'
                },
                {
                    nombre: 'MIX TROPICAL',
                    imageName: 'FOTO-142.jpg'
                },
                {
                    nombre: 'MIX ESPECIAL',
                    imageName: 'FOTO-143.jpg'
                }
            ]
        },
        {
            nombre: 'Galletitas',
            folderName:' ../../assets/img/galletitas/',
            subProductos:[
                {
                    nombre: 'Dimax pepas Batata',
                    imageName: 'g1.jpg'
                },
                {
                    nombre: 'Dimax pepas chocolate',
                    imageName: 'g2.jpg'
                },
                {
                    nombre: 'Dimax Semillas',
                    imageName: 'g3.jpg'
                },
                {
                    nombre: 'Dimax Fajitas',
                    imageName: 'g4.jpg'
                },
                {
                    nombre: 'Dimax Salados',
                    imageName: 'g5.jpg'
                },
                {
                    nombre: 'Dimax Vainilla',
                    imageName: 'g6.jpg'
                },
                {
                    nombre: 'DELICEL CHOCOLATE',
                    imageName: 'g7.jpg'
                },
                {
                    nombre: 'DELICEL COCO',
                    imageName: 'g8.jpg'
                },
                {
                    nombre: 'DELICEL LIMON',
                    imageName: 'g9.jpg'
                },
                {
                    nombre: 'DELICEL MEMBRILLO',
                    imageName: 'g10.jpg'
                },
                {
                    nombre: 'DELICEL NARANJA',
                    imageName: 'g11.jpg'
                },
                {
                    nombre: 'DELICEL VAINILLA',
                    imageName: 'g12.jpg'
                },
                {
                    nombre: 'Palluzzi Chocolate',
                    imageName: 'g13.jpg'
                },
                {
                    nombre: 'Palluzzi Maicena',
                    imageName: 'g14.jpg'
                },
                {
                    nombre: 'Palluzzi Membrillo',
                    imageName: 'g15.jpg'
                },
                {
                    nombre: 'Palluzzi Agua',
                    imageName: 'g16.jpg'
                },
                {
                    nombre: 'Palluzzi Merenguito',
                    imageName: 'g17.jpg'
                },
                {
                    nombre: 'Palluzzi Nuez',
                    imageName: 'g18.jpg'
                },
                {
                    nombre: 'Palluzzi Palmeritas',
                    imageName: 'g19.jpg'
                },
                {
                    nombre: 'Palluzzi Uva',
                    imageName: 'g20.jpg'
                },
                {
                    nombre: 'Pepas Membrillo y Chia x250grs',
                    imageName: 'g21.jpg'
                },
                {
                    nombre: 'Cookies Limon y Chia x200grs',
                    imageName: 'g22.jpg'
                },
                {
                    nombre: 'Cookies Naranja y Lino x200 grs',
                    imageName: 'g23.jpg'
                },
                {
                    nombre: 'Cookies  Vainilla y Pasas de Uva x200 grs',
                    imageName: 'g24.jpg'
                },
                {
                    nombre: 'Pepas de Membrillo y Chia x 1 kg',
                    imageName: 'g25.jpg'
                }
            ]
        },
        {
            nombre: 'Productos Varios',
            folderName:' ../../assets/img/productos-varios/',
            subProductos:[
                {
                    nombre: 'AMANDE LECHE DE ALMENDRAS',
                    imageName: 'FOTO-138.jpg'
                },
                {
                    nombre: 'AMANDE LECHE COCO Y ALMENDRAS',
                    imageName: 'FOTO-139.jpg'
                },
                {
                    nombre: 'AMANDE LECHE DE MANI Y ALMENDRAS',
                    imageName: 'FOTO-140.jpg'
                },
                {
                    nombre: 'CHOCOLART ALMENDRAS, ARANDANOS, AVELLANAS, MANI Y PASAS CON CHOCOLATE',
                    imageName: 'FOTO-141.jpg'
                },
                {
                    nombre: 'EL BONCAMINO MILANESAS DE SOJA RELLENAS',
                    imageName: 'FOTO-142.jpg'
                },
                {
                    nombre: 'EPUYEN ALFAJOR AVENTA Y CHIA',
                    imageName: 'FOTO-143.jpg'
                },
                {
                    nombre: 'EPUYEN ALFAJOR CHOCOLATE SIN AZUCAR',
                    imageName: 'FOTO-144.jpg'
                },
                {
                    nombre: 'EPUYEN BROWNIE SIN AZUCAR',
                    imageName: 'FOTO-145.jpg'
                },
                {
                    nombre: 'EPIKA TARTAS ARTESANALES',
                    imageName: 'FOTO-146.jpg'
                },
                {
                    nombre: 'IRWO TRUFAS',
                    imageName: 'FOTO-147.jpg'
                },
                {
                    nombre: 'IRWO ALFAJOR INTEGRAL',
                    imageName: 'FOTO-148.jpg'
                },
                {
                    nombre: 'IRWO BOCADITOS HIGO Y NUEZ',
                    imageName: 'FOTO-149.jpg'
                },
                {
                    nombre: 'LASFOR ALMOHADITAS',
                    imageName: 'FOTO-150.jpg'
                },
                {
                    nombre: 'CARAMELOS PROPOLEO',
                    imageName: 'FOTO-151.jpg'
                },
                {
                    nombre: 'CARAMELOS AMBAY',
                    imageName: 'FOTO-152.jpg'
                },
                {
                    nombre: 'ORANN GRANOLA ENERGETICA',
                    imageName: 'FOTO-153.jpg'
                },
                {
                    nombre: 'ORANN GRANOLA CLASICA',
                    imageName: 'FOTO-154.jpg'
                },
                {
                    nombre: 'ORANN GRANOLA SIN ENDULZAR',
                    imageName: 'FOTO-155.jpg'
                },
                {
                    nombre: 'ORANN GRANOLA LIGHT',
                    imageName: 'FOTO-156.jpg'
                },
                {
                    nombre: 'SIN CULPA ALFAJOR FRUTILLA BAÑADO CHOCOLATE',
                    imageName: 'FOTO-157.jpg'
                }             
            ]
        },
        {
            nombre: 'Miel de Abejas',
            folderName:' ../../assets/img/miel/',
            subProductos:[
                {
                    nombre: 'Miel 250 grs',
                    imageName: 'm1.jpg'
                },
                {
                    nombre: 'Miel 500 grs',
                    imageName: 'm2.jpg'
                },
                {
                    nombre: 'Miel 750 grs',
                    imageName: 'm3.jpg'
                },
                {
                    nombre: 'Miel 980 grs',
                    imageName: 'm4.jpg'
                },
                {
                    nombre: 'Polen importado',
                    imageName: 'm5.jpg'
                }
            ]
        },
        {
            nombre: 'Frutas Deshidratadas',
            folderName:' ../../assets/img/frutas-deshidratadas/',
            subProductos:[
                {
                    nombre: 'Ciruelas sin carozo 88/110',
                    imageName: 'fd1.jpg'
                },
                {
                    nombre: 'Ciruelas Sin Carozo 132/154',
                    imageName: 'fd2.jpg'
                },
                {
                    nombre: 'Ciruelas Con Carozo 88/110',
                    imageName: 'fd3.jpg'
                },
                {
                    nombre: 'Ciruelas Presidente',
                    imageName: 'fd4.jpg'
                },
                {
                    nombre: 'Higo Negro',
                    imageName: 'fd5.jpg'
                },
                {
                    nombre: 'Higo Negro Glaseado',
                    imageName: 'fd6.jpg'
                },
                {
                    nombre: 'Anana Glaseado Rodajas',
                    imageName: 'fd7.jpg'
                },
                {
                    nombre: 'Anana cubos colores',
                    imageName: 'fd8.jpg'
                },
                {
                    nombre: 'Anana Cubo Blanco',
                    imageName: 'fd9.jpg'
                },
                {
                    nombre: 'Arandanos Chilenos',
                    imageName: 'fd10.jpg'
                },
                {
                    nombre: 'Duraznos',
                    imageName: 'fd11.jpg'
                },
                {
                    nombre: 'Peras',
                    imageName: 'fd12.jpg'
                },
                {
                    nombre: 'Jengibre Rodajas',
                    imageName: 'fd13.jpg'
                },
                {
                    nombre: 'Jengibre cubos',
                    imageName: 'fd14.jpg'
                },
                {
                    nombre: 'Papaya Cubos',
                    imageName: 'fd15.jpg'
                },
                {
                    nombre: 'Manzana Cubos',
                    imageName: 'fd16.jpg'
                },
                {
                    nombre: 'Empaque Mixto',
                    imageName: 'fd17.jpg'
                },
                {
                    nombre: 'Coco Rallado',
                    imageName: 'fd18.jpg'
                },
                {
                    nombre: 'BANANAS',
                    imageName: 'FOTO-119.jpg'
                },
                {
                    nombre: 'ARANDANOS DESHIDRATADOS',
                    imageName: 'FOTO-120.jpg'
                },
                {
                    nombre: 'DAMASCOS',
                    imageName: 'FOTO-121.jpg'
                },
                {
                    nombre: 'DATILES',
                    imageName: 'FOTO-122.jpg'
                },
                {
                    nombre: 'HIGOS TURCOS',
                    imageName: 'FOTO-123.jpg'
                },
                {
                    nombre: 'PASAS DE UVA JUMBO',
                    imageName: 'FOTO-124.jpg'
                },
                {
                    nombre: 'PASAS DE UVA FLAME',
                    imageName: 'FOTO-125.jpg'
                },
                {
                    nombre: 'PASAS DE UVA RUBIAS',
                    imageName: 'FOTO-126.jpg'
                },
                {
                    nombre: 'PASAS DE UVA SULTANINAS',
                    imageName: 'FOTO-127.jpg'
                },
                {
                    nombre: 'TOMATE DESHIDRATADO',
                    imageName: 'FOTO-128.jpg'
                }
            ]
        },
        {
            nombre: 'Suplementos Dietarios',
            folderName:' ../../assets/img/suplementos-dietarios/',
            subProductos:[
                {
                    imageName: 'sd1.jpg'
                },
                {
                    imageName: 'sd2.jpg'
                },
                {
                    imageName: 'sd3.jpg'
                },
                {
                    imageName: 'sd4.jpg'
                },
                {
                    imageName: 'sd5.jpg'
                },
                {
                    imageName: 'sd6.jpg'
                },
                {
                    imageName: 'sd7.jpg'
                },
                {
                    imageName: 'sd8.jpg'
                },
                {
                    imageName: 'sd9.jpg'
                },
                {
                    imageName: 'sd10.jpg'
                },
                {
                    imageName: 'sd11.jpg'
                },
                {
                    imageName: 'sd12.jpg'
                },
                {
                    imageName: 'sd13.jpg'
                },
                {
                    imageName: 'sd14.jpg'
                },
                {
                    imageName: 'sd15.jpg'
                },
                {
                    imageName: 'sd16.jpg'
                },
                {
                    imageName: 'sdb1.png'
                },
                {
                    imageName: 'sdb2.png'
                },
                {
                    imageName: 'sdb3.png'
                },
                {
                    imageName: 'sdb4.png'
                }
            ]
        },
        {
            nombre: 'Panificados',
            folderName:' ../../assets/img/panificados/',
            subProductos:[
                {
                    nombre: 'GALLETITAS LA ZARANDA SIN AZUCAR',
                    imageName: 'FOTO-129.jpg'
                },
                {
                    nombre: 'PAN LA ZARANDA MULTICEREAL',
                    imageName: 'FOTO-130.jpg'
                },
                {
                    nombre: 'PAN LA ZARANDA MULTIGRANO CON AVENA',
                    imageName: 'FOTO-131.jpg'
                },
                {
                    nombre: 'PAN LA ZARANDA CENTENO',
                    imageName: 'FOTO-132.jpg'
                },
                {
                    nombre: 'PAN LA ZARANDA GIRASOL',
                    imageName: 'FOTO-133.jpg'
                },
                {
                    nombre: 'PAN LA ZARANDA SALVADO INTEGRAL CON SAL',
                    imageName: 'FOTO-134.jpg'
                },
                {
                    nombre: 'PAN LA FRANCIA VARIOS',
                    imageName: 'FOTO-135.jpg'
                },
                {
                    nombre: 'LA FRANCIA PEPAS DE MEMBRILLO X 250 GR',
                    imageName: 'FOTO-136.jpg'
                },
                {
                    nombre: 'LA FRANCIA PEPAS DE MEMBRILLO BANDEJA X 1 KG',
                    imageName: 'FOTO-137.jpg'
                }
            ]
        },
        {
            nombre: 'Productos Apto Celiacos',
            folderName:' ../../assets/img/productos-celiacos/',
            subProductos:[
                {
                    nombre: 'CHIPS PAPAS',
                    imageName: 'FOTO-13.jpg'
                },
                {
                    nombre: 'CHIPS BATATAS',
                    imageName: 'FOTO-14.jpg'
                },
                {
                    nombre: 'GALLETITAS DULCES DE VAINILLA',
                    imageName: 'FOTO-15.jpg'
                },
                {
                    nombre: 'REBOZADOR',
                    imageName: 'FOTO-16.jpg'
                },
                {
                    nombre: 'ALFAJOR HARINA DE  ZAPALLO Y ALGARROBA',
                    imageName: 'FOTO-17.jpg'
                },
                {
                    nombre: 'ALFAJOR HARINA DE NUEZ BLANCO',
                    imageName: 'FOTO-18.jpg'
                },
                {
                    nombre: 'ALFAJOR HARINA AVELLANA NEGRO',
                    imageName: 'FOTO-19.jpg'
                },
                {
                    nombre: 'ALFJAOR HARINA AVELLANA BLANCO',
                    imageName: 'FOTO-20.jpg'
                },
                {
                    nombre: 'ALFAJOR HARINA DE MANI',
                    imageName: 'FOTO-21.jpg'
                },
                {
                    nombre: 'ALFAJOR CHOCOLATE DULCE LECHE SIN TACC',
                    imageName: 'FOTO-22.jpg'
                },
                {
                    nombre: 'ALFAJOR MOUSSE LIMON /CHOCOLATE SIN TACC',
                    imageName: 'FOTO-23.jpg'
                },
                {
                    nombre: 'ALFAJOR MOUSSE CHOCOLATE',
                    imageName: 'FOTO-24.jpg'
                },
                {
                    nombre: 'ALFAJOR MOUSSE LIMON',
                    imageName: 'FOTO-25.jpg'
                },
                {
                    nombre: 'ALFAJOR MOUSSE FRESAS',
                    imageName: 'FOTO-26.jpg'
                },
                {
                    nombre: 'ALFAJOR MOUSSE VAINILLA',
                    imageName: 'FOTO-27.jpg'
                },
                {
                    nombre: 'ALFAJOR DULCE LECHE SIN AZUCAR',
                    imageName: 'FOTO-28.jpg'
                },
                {
                    nombre: 'PAN DE MOLDE SIN TACC',
                    imageName: 'FOTO-29.jpg'
                },
                {
                    nombre: 'PAN DE MOLDE VEGANO',
                    imageName: 'FOTO-30.jpg'
                },
                {
                    nombre: 'BOMBONES DE FRUTA',
                    imageName: 'FOTO-31.jpg'
                },
                {
                    nombre: 'BOCADITOS DE CHOCOLATE',
                    imageName: 'FOTO-32.jpg'
                },
                {
                    nombre: 'PIZZAS SIN TACC',
                    imageName: 'FOTO-33.jpg'
                },
                {
                    nombre: 'ALFAJOR ENDULZADO CON STEVIA SIN AZUCAR',
                    imageName: 'FOTO-34.jpg'
                },
                {
                    nombre: 'ALFAJOR LECHE Y FRUTA',
                    imageName: 'FOTO-35.jpg'
                },
                {
                    nombre: 'CONITOS ENDULZADOS CON STEVIA',
                    imageName: 'FOTO-36.jpg'
                },
                {
                    nombre: 'GALLETITAS SABOR COCO',
                    imageName: 'FOTO-37.jpg'
                },
                {
                    nombre: 'GALLETITAS SABOR VAINILLA',
                    imageName: 'FOTO-38.jpg'
                },
                {
                    nombre: 'GALLETITAS SABOR NARANJA',
                    imageName: 'FOTO-39.jpg'
                },
                {
                    nombre: 'GALLETITAS SIN GLUTEN SIN LACTOSA DIFERENTES SABORES',
                    imageName: 'FOTO-40.jpg'
                },
                {
                    nombre: 'PREMEZCLA PAN',
                    imageName: 'FOTO-41.jpg'
                },
                {
                    nombre: 'PREMEZCLA PIZZA',
                    imageName: 'FOTO-42.jpg'
                },
                {
                    nombre: 'PREMEZCLA BIZCOCHUELO CHOCOLATE',
                    imageName: 'FOTO-43.jpg'
                },
                {
                    nombre: 'PREMEZCLA BIZCOCHUELO VAINILLA',
                    imageName: 'FOTO-44.jpg'
                },
                {
                    nombre: 'PREMEZCLA UNIVERSAL',
                    imageName: 'FOTO-45.jpg'
                },
                {
                    nombre: 'PREMEZCLA ÑOQUIS',
                    imageName: 'FOTO-46.jpg'
                },
                {
                    nombre: 'TAPAS DE EMPANADAS',
                    imageName: 'FOTO-47.jpg'
                },
                {
                    nombre: 'TAPAS DE PASCUALINA',
                    imageName: 'FOTO-48.jpg'
                },
                {
                    nombre: 'MILANESAS DE SOJA',
                    imageName: 'FOTO-49.jpg'
                },
                {
                    nombre: 'PIZZETAS',
                    imageName: 'FOTO-50.jpg'
                },
                {
                    nombre: 'PAN LACTAL FETEADO',
                    imageName: 'FOTO-51.jpg'
                },
                {
                    nombre: 'PIONONO',
                    imageName: 'FOTO-52.jpg'
                },
                {
                    nombre: 'BUDIN VAINILLA',
                    imageName: 'FOTO-53.jpg'
                },
                {
                    nombre: 'BUDIN CON FRUTAS',
                    imageName: 'FOTO-54.jpg'
                },
                {
                    nombre: 'BUDIN MARMOLADO',
                    imageName: 'FOTO-55.jpg'
                },
                {
                    nombre: 'BUDIN CON CHIPS DE CHOCOLATE',
                    imageName: 'FOTO-56.jpg'
                },
                {
                    nombre: 'PALITOS COPETIN',
                    imageName: 'FOTO-57.jpg'
                },
                {
                    nombre: 'GALLETITAS VARIOS SABORES',
                    imageName: 'FOTO-58.jpg'
                },
                {
                    nombre: 'PREMEZCLA UNIVERSAL',
                    imageName: 'FOTO-59.jpg'
                },
                {
                    nombre: 'FECULA DE MANDIOCA',
                    imageName: 'FOTO-60.jpg'
                },
                {
                    nombre: 'HARINA DE ARROZ',
                    imageName: 'FOTO-61.jpg'
                },
                {
                    nombre: 'ALMIDON DE MAIZ',
                    imageName: 'FOTO-62.jpg'
                },
                {
                    nombre: 'PAN RALLADO',
                    imageName: 'FOTO-63.jpg'
                },
                {
                    nombre: 'CHOCO ARROZ BLANCO',
                    imageName: 'FOTO-64.jpg'
                },
                {
                    nombre: 'CHOCO ARROZ DULCE DE LECHE',
                    imageName: 'FOTO-65.jpg'
                },
                {
                    nombre: 'CHOCO ARROZ FRUTILLA',
                    imageName: 'FOTO-66.jpg'
                },
                {
                    nombre: 'CHOCO ARROZ LIMON',
                    imageName: 'FOTO-67.jpg'
                },
                {
                    nombre: 'CHOCO BAR NEGRO',
                    imageName: 'FOTO-68.jpg'
                },
                {
                    nombre: 'OBLEA',
                    imageName: 'FOTO-69.jpg'
                },
                {
                    nombre: 'YOGUBAR',
                    imageName: 'FOTO-70.jpg'
                },
                {
                    nombre: 'PAN REBANADO LIVIANO',
                    imageName: 'FOTO-71.jpg'
                },
                {
                    nombre: 'PREMEZCLA',
                    imageName: 'FOTO-72.jpg'
                },
                {
                    nombre: 'REBOZADOR',
                    imageName: 'FOTO-73.jpg'
                },
                {
                    nombre: 'MERMELADAS LIGHT CON STEVIA',
                    imageName: 'FOTO-74.jpg'
                },
                {
                    nombre: 'JUGOS DIFERENTES SABORES',
                    imageName: 'FOTO-75.jpg'
                },
                {
                    nombre: 'PASTA DE MANI',
                    imageName: 'FOTO-76.jpg'
                },
                {
                    nombre: 'MEDALLONES ACELGA',
                    imageName: 'FOTO-77.jpg'
                },
                {
                    nombre: 'MEDALLONES ZANAHORIA',
                    imageName: 'FOTO-78.jpg'
                },
                {
                    nombre: 'MEDALLONES CHOCLO',
                    imageName: 'FOTO-79.jpg'
                },
                {
                    nombre: 'MEDALLONES LENTEJA',
                    imageName: 'FOTO-80.jpg'
                },
                {
                    nombre: 'REBOZADORES',
                    imageName: 'FOTO-81.jpg'
                },
                {
                    nombre: 'ALFAJOR APTO CELIACOS BAÑADO CHOCOLATE',
                    imageName: 'FOTO-82.jpg'
                },
                {
                    nombre: 'ALFAJOR APTO CELIACOS',
                    imageName: 'FOTO-83.jpg'
                },
                {
                    nombre: 'PALMERITAS',
                    imageName: 'FOTO-84.jpg'
                },
                {
                    nombre: 'GALLETITAS DE AGUA',
                    imageName: 'FOTO-85.jpg'
                },
                {
                    nombre: 'ALFAJORES DE MAICENA',
                    imageName: 'FOTO-86.jpg'
                },
                {
                    nombre: 'MASAS SECAS',
                    imageName: 'FOTO-87.jpg'
                },
                {
                    nombre: 'MERENGUITOS',
                    imageName: 'FOTO-88.jpg'
                },
                {
                    nombre: 'PALMERITAS',
                    imageName: 'FOTO-89.jpg'
                },
                {
                    nombre: 'PEPAS DIFERENTES SABORES',
                    imageName: 'FOTO-90.jpg'
                },
                {
                    nombre: 'SCONS DIFERENES SABORES',
                    imageName: 'FOTO-91.jpg'
                },
                {
                    nombre: 'VAINILLAS',
                    imageName: 'FOTO-92.jpg'
                },
                {
                    nombre: 'EMPANADAS DIFERENTES SABORES',
                    imageName: 'FOTO-93.jpg'
                },
                {
                    nombre: 'RAVIOLES DIFERENTES SABORES',
                    imageName: 'FOTO-94.jpg'
                },
                {
                    nombre: 'SORRENTINOS DIFERENTES SABORES',
                    imageName: 'FOTO-95.jpg'
                },
                {
                    nombre: 'TOSTADAS DE ARROZ CON SAL',
                    imageName: 'FOTO-96.jpg'
                },
                {
                    nombre: 'TOSTADAS DE ARROZ SIN SAL',
                    imageName: 'FOTO-97.jpg'
                },
                {
                    nombre: 'TOSTADAS DE ARROZ DULCES',
                    imageName: 'FOTO-98.jpg'
                },
                {
                    nombre: 'ALFAJORES SANTA MARIA DULCE DE LECHE C/ CHOCOLATE',
                    imageName: 'FOTO-99.jpg'
                },
                {
                    nombre: 'GALLLETITAS VARIOS SABORES',
                    imageName: 'FOTO-100.jpg'
                },
                {
                    nombre: 'OBLEAS BAÑADAS EN CHOCOLATE',
                    imageName: 'FOTO-101.jpg'
                },
                {
                    nombre: 'OBLEAS DE FRUTILLA',
                    imageName: 'FOTO-102.jpg'
                },
                {
                    nombre: 'PREMEZCLA PARA PANADERIA Y REPOSTERIA ROJA X 1 KG',
                    imageName: 'FOTO-103.jpg'
                },
                {
                    nombre: 'PREMEZCLA PARA PANADERIA Y REPOSTERIA SIN LACTOSA VERDE X 1 KG',
                    imageName: 'FOTO-104.jpg'
                },
                {
                    nombre: 'REBOZADOR',
                    imageName: 'FOTO-105.jpg'
                }
            ]
        },
        {
            nombre: 'Hierbas e Infusiones',
            redirectPath: '/hierbas-del-oasis'
        }
    ];

    producto_selected:Productos = null;

    constructor(private _router:Router) { }

    ngOnInit() {
        this.producto_selected = this.productos[0];
    }


    seleccionarProducto(p:Productos){
        p.redirectPath ? this._router.navigateByUrl(p.redirectPath) : this.producto_selected = p;
    }


}
