import { Component, OnInit, ElementRef } from '@angular/core';
import { Location, ViewportScroller } from '@angular/common';
import { MenuItem, TypeEnum } from './model/MenuItem';
import { AuthService } from '@auth0/auth0-angular';
import { DownloadService } from 'app/shared/services/download.service';
import {MatSnackBar} from '@angular/material/snack-bar';

@Component({
    selector: 'app-navbar',
    templateUrl: './navbar.component.html',
    styleUrls: ['./navbar.component.scss']
})
export class NavbarComponent implements OnInit {
    private toggleButton: any;
    private sidebarVisible: boolean;
    public menu_items:Array<MenuItem>;
    public typeEnum = TypeEnum;
    public displayProgressbar:boolean =false;

    constructor(public location: Location, private element : ElementRef, public viewportScroller:ViewportScroller, private auth: AuthService,private _downloadService:DownloadService,private _snackBar: MatSnackBar) {
        this.sidebarVisible = false;
        this.createMenu();
    }

    ngOnInit() {
        const navbar: HTMLElement = this.element.nativeElement;
        this.toggleButton = navbar.getElementsByClassName('navbar-toggler')[0];
    }
    sidebarOpen() {
        const toggleButton = this.toggleButton;
        const html = document.getElementsByTagName('html')[0];

        setTimeout(function(){
            toggleButton.classList.add('toggled');
        }, 500);
        html.classList.add('nav-open');

        this.sidebarVisible = true;
    };
    sidebarClose() {
        const html = document.getElementsByTagName('html')[0];
        // console.log(html);
        this.toggleButton.classList.remove('toggled');
        this.sidebarVisible = false;
        html.classList.remove('nav-open');
    };
    sidebarToggle() {
        // const toggleButton = this.toggleButton;
        // const body = document.getElementsByTagName('body')[0];
        if (this.sidebarVisible === false) {
            this.sidebarOpen();
        } else {
            this.sidebarClose();
        }
    };
    
    createMenu(){
        this.menu_items = [
            {
                name:"Home",
                scroll:true,
                anchor:"home",
                path:"/home",
                type: TypeEnum.link
            },
            {
                name:"Contacto",
                scroll:true,
                path:"/home",
                anchor:"contacto",
                type: TypeEnum.link
            },
            {
                name:"Nosotros",
                scroll:true,
                path:"/home",
                anchor:"nosotros",
                type: TypeEnum.link
            },
            {
                name:"Hierbas del Oasis",
                scroll:false,
                path:"/hierbas-del-oasis",
                type: TypeEnum.link
            },
            {
                name:"Productos",
                scroll:false,
                path:"/productos",
                type: TypeEnum.link
            },
            {
                name:"Ofertas",
                scroll:false,
                icon: 'nc-icon nc-cloud-download-93',
                type: TypeEnum.button,
                tooltip: 'Descargar Ofertas',
                function: 'download_ofertas'
            },
            {
                name:"Lista Mundo Pampa",
                scroll: false,
                icon: 'nc-icon nc-cloud-download-93',
                type: TypeEnum.button,
                tooltip: 'Descargar Listado',
                function: 'log_and_download'
            }
        ];

    }


    scroll(item:MenuItem) {
        if(item.scroll){
            this.viewportScroller.setOffset([0,70])
            this.viewportScroller.scrollToAnchor(item.anchor);
        }
    }

    public excecuteFunction(function_name:string){
        return this[function_name]();
    }

    public log_and_download(){

      this.auth.isAuthenticated$.subscribe(auth => {
            if(!auth){
                this.auth.loginWithPopup().subscribe(r => {},error => {});   
            }
            else{
                this.auth.getAccessTokenSilently().subscribe(t => { this.getPdf(t); });
            }
        });
    }

    private getPdf(tk:string){
        if(!this.displayProgressbar){
            this.displayProgressbar = true;
            this._downloadService.getUrlSign(tk).subscribe(r => {
                if(r.SignedUrl){
                    this.createClickToDownload(r.SignedUrl);
                }   
            },
            error => {this._snackBar.open(error.error, "X"); this.displayProgressbar = false});
        }
    }

    public download_ofertas(){

        if(!this.displayProgressbar){
            this.displayProgressbar = true;
            this._downloadService.getUrlWauthSign().subscribe(r => {
                if(r.SignedUrl){
                    this.createClickToDownload(r.SignedUrl);
                }   
            },
            error => {this._snackBar.open(error.error, "X"); this.displayProgressbar = false});
        }
    }

    private createClickToDownload(SignedUrl){
        let linkToDownload = document.createElement('a');
        linkToDownload.href = SignedUrl;
        linkToDownload.click();
        this.displayProgressbar = false
    }
}
