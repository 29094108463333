export class MenuItem{
    name:string;
    icon?:string;
    path?:string;
    anchor?:string
    scroll:boolean;
    children?:Array<MenuItem>;
    type?:TypeEnum;
    tooltip?:string;
    function?:string;
}

export enum TypeEnum{
    link,
    button
}

