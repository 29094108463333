import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { FormsModule } from '@angular/forms';
import { NouisliderModule } from 'ng2-nouislider';
import { JwBootstrapSwitchNg2Module } from 'jw-bootstrap-switch-ng2';
import { RouterModule } from '@angular/router';

import { ComponentsComponent } from './main.component';
import { HierbasDelOasisComponent } from './hierbasdeloasis/hierbasdeloasis.component';
import { PdfViewerModule } from 'ng2-pdf-viewer';
import { ProductosComponent } from './productos/productos.component';
import { MatListModule } from '@angular/material/list';
import { MatCardModule } from '@angular/material/card';

@NgModule({
    imports: [
        CommonModule,
        FormsModule,
        NgbModule,
        NouisliderModule,
        RouterModule,
        JwBootstrapSwitchNg2Module,
        PdfViewerModule,
        MatListModule,
        MatCardModule
    ],
    declarations: [
        ComponentsComponent,
        HierbasDelOasisComponent,
        ProductosComponent
    ],
    entryComponents: [],
    exports:[ ComponentsComponent ]
})
export class ComponentsModule { }
