import { NgModule } from '@angular/core';
import { CommonModule, } from '@angular/common';
import { BrowserModule  } from '@angular/platform-browser';
import { Routes, RouterModule } from '@angular/router';

import { ComponentsComponent } from './components/main.component';
import { HierbasDelOasisComponent } from './components/hierbasdeloasis/hierbasdeloasis.component';
import { ProductosComponent } from './components/productos/productos.component';

const routes: Routes =[
    { path: '', redirectTo: 'home', pathMatch: 'full' },
    { path: 'home',             component: ComponentsComponent },
    { path: 'hierbas-del-oasis',     component: HierbasDelOasisComponent },
    { path: 'productos',      component: ProductosComponent }
];

@NgModule({
  imports: [
    CommonModule,
    BrowserModule,
    RouterModule.forRoot(routes,{ anchorScrolling : "enabled"})
  ],
  exports: [
  ],
})
export class AppRoutingModule { }
